import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Icon, makeStyles } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';

import BulkApiActionButton from 'admin/assets/js/components/buttons/BulkApiActionButton.jsx';
import {
  CANCELLATION_REASON,
  TRANSACTION_STATUS,
  TRANSACTION_METHOD,
} from 'finance/assets/js/constants';

const useStyles = makeStyles({
  container: {
    '& :not(:last-child)': {
      marginRight: '10px',
    },
  },
});

const TransactionViewActions = ({ transaction }) => {
  const [reason, setReason] = useState(Object.keys(CANCELLATION_REASON)[0]);
  const classes = useStyles();
  if (!transaction) {
    return null;
  }

  const {
    id, method, status = TRANSACTION_STATUS.PENDING,
    pn_client_reference_id: pnClientReferenceId,
    wp_transaction_reference: wpTransactionReference,
  } = transaction;

  return (
    <div className={classes.container}>
      <BulkApiActionButton
        variant="contained"
        size="medium"
        label="Recreate"
        title="Recreate transaction"
        content="Are you sure you want to recreate a transaction for this invoice?  If this invoice is deferred, then this will recreate a single transaction for all invoices in the group."
        ids={[id]}
        icon={<ReplayIcon />}
        resource="transactions"
        action="recreate_transactions"
        disabled={[TRANSACTION_STATUS.SUCCEEDED].includes(status)}
      />
      {method === TRANSACTION_METHOD.TRANSFERWISE && (
        <BulkApiActionButton
          variant="contained"
          size="medium"
          title={`Sync Wise transfer #${id}`}
          content={`
              Transfer ${id} will be synced. Proceed with caution.
            `}
          confirm
          icon={<Icon>refresh</Icon>}
          ids={[id]}
          label="Sync"
          resource="dim_transferwise_transfers"
          action="sync-trw-transfers"
          cancelLabel="Close"
        />
      )}
      {method === TRANSACTION_METHOD.PAYONEER && (
        <BulkApiActionButton
          variant="contained"
          size="medium"
          title={`Sync payout #${pnClientReferenceId}`}
          content={`
                Payout will be synced. Proceed with caution.
              `}
          confirm
          icon={<Icon>refresh</Icon>}
          label="Sync"
          ids={[pnClientReferenceId]}
          resource="dim_payoneer_payouts"
          action="sync-payoneer-payouts"
          cancelLabel="Close"
        />
      )}
      {method === TRANSACTION_METHOD.WORLDPAY && (
        <BulkApiActionButton
          variant="contained"
          size="medium"
          title={`Sync payout #${wpTransactionReference}`}
          content={`
                Payout will be synced. Proceed with caution.
              `}
          confirm
          icon={<Icon>refresh</Icon>}
          label="Sync"
          ids={[wpTransactionReference]}
          resource="dim_worldpay_payouts"
          action="sync-worldpay-payouts"
          cancelLabel="Close"
        />
      )}
      <BulkApiActionButton
        variant="contained"
        size="medium"
        title={`Cancel transaction #${id}`}
        content={(
          <>
            <p>{`Transaction ${id} will be cancelled. Proceed with caution.`}</p>
            <select
              name="cancel_reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            >
              {Object.entries(CANCELLATION_REASON)
                .map(([k, v]) => (<option id={k} value={k}>{v}</option>))}
            </select>
          </>
        )}
        confirm
        icon={<Icon>cancel</Icon>}
        ids={[id]}
        payload={{ reason: CANCELLATION_REASON[reason] }}
        label="Cancel"
        resource="transactions"
        action="cancel-transactions"
        cancelLabel="Close"
        disabled={[
          TRANSACTION_STATUS.SUCCEEDED,
          TRANSACTION_STATUS.FAILED,
          TRANSACTION_STATUS.CANCELLED,
          TRANSACTION_STATUS.REFUNDED,
          TRANSACTION_STATUS.REMOVED,
        ].includes(status)}
      />
    </div>
  );
};

TransactionViewActions.propTypes = {
  transaction: PropTypes.object,
};

TransactionViewActions.defaultProps = {
  transaction: {},
};

export default TransactionViewActions;
