/* eslint react/prop-types: "warn", import/prefer-default-export: "warn" */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery, Icon } from '@material-ui/core';
import { DashboardMenuItem, MenuItemLink } from 'react-admin';

import { resourcesSpec } from 'admin/assets/js/resources';

import SubMenu from './SubMenu.jsx';
import { getIsSuperAdmin } from 'admin/assets/js/lib/utils';

const Menu = ({ onMenuClick, dense, logout }) => {
  const isSuperAdmin = getIsSuperAdmin();
  const [state, setState] = useState({
    integrations: false,
    menuCatalog: false,
    menuCustomers: false,
    payments: false,
    reports: false,
  });
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const open = useSelector(s => s.admin.ui.sidebarOpen);
  useSelector(s => s.theme); // force rerender on theme change

  const handleToggle = (menu) => {
    setState(s => ({ ...s, [menu]: !s[menu] }));
  };

  return (
    <div>
      {' '}
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      <MenuItemLink
        to="/organizations"
        primaryText={resourcesSpec.organizations.title}
        leftIcon={<resourcesSpec.organizations.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to="/users"
        primaryText={resourcesSpec.users.title}
        leftIcon={<resourcesSpec.users.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <SubMenu
        handleToggle={() => handleToggle('payments')}
        isOpen={state.payments}
        sidebarIsOpen={open}
        name="td.menu.payments"
        icon={<Icon>payment</Icon>}
        dense={dense}
      >
        <MenuItemLink
          to="/pricings"
          primaryText={resourcesSpec.pricings.title}
          leftIcon={<resourcesSpec.pricings.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/invoices"
          primaryText={resourcesSpec.invoices.title}
          leftIcon={<resourcesSpec.invoices.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/run_invoicing"
          primaryText={resourcesSpec.run_invoicing.title}
          leftIcon={<resourcesSpec.run_invoicing.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/cashout_requests/requests"
          primaryText={resourcesSpec.cashout_requests.title}
          leftIcon={<resourcesSpec.cashout_requests.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/transactions"
          primaryText={resourcesSpec.transactions.title}
          leftIcon={<resourcesSpec.transactions.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/dim_billing_processes"
          primaryText={resourcesSpec.dim_billing_processes.title}
          leftIcon={<resourcesSpec.dim_billing_processes.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/on_demand_payouts"
          primaryText={resourcesSpec.on_demand_payouts.title}
          leftIcon={<resourcesSpec.on_demand_payouts.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/inbound_transfers"
          primaryText={resourcesSpec.inbound_transfers.title}
          leftIcon={<resourcesSpec.inbound_transfers.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        {
          isSuperAdmin && (
            <>
              <MenuItemLink
                to="bank_accounts/all"
                primaryText={resourcesSpec.bank_accounts.title}
                leftIcon={<resourcesSpec.bank_accounts.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
              />
            </>
          )
        }
        <MenuItemLink
          to="/subscriptions"
          primaryText={resourcesSpec.subscriptions.title}
          leftIcon={<resourcesSpec.subscriptions.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/licences"
          primaryText={resourcesSpec.licences.title}
          leftIcon={<resourcesSpec.licences.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/pricing_scales"
          primaryText={resourcesSpec.pricing_scales.title}
          leftIcon={<resourcesSpec.pricing_scales.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/invoicing_entities"
          primaryText={resourcesSpec.invoicing_entities.title}
          leftIcon={<resourcesSpec.invoicing_entities.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/org_billing_analytics"
          primaryText={resourcesSpec.org_billing_analytics.title}
          leftIcon={<resourcesSpec.org_billing_analytics.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/fact_audit_runs"
          primaryText={resourcesSpec.fact_audit_runs.title}
          leftIcon={<resourcesSpec.fact_audit_runs.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/system_bank_account_overrides"
          primaryText={resourcesSpec.system_bank_account_overrides.title}
          leftIcon={<resourcesSpec.system_bank_account_overrides.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <MenuItemLink
        to="/service_orders"
        primaryText={resourcesSpec.service_orders.title}
        leftIcon={<resourcesSpec.service_orders.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to="/invitations"
        primaryText={resourcesSpec.invitations.title}
        leftIcon={<resourcesSpec.invitations.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to="/notifications"
        primaryText={resourcesSpec.notifications.title}
        leftIcon={<resourcesSpec.notifications.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <SubMenu
        handleToggle={() => handleToggle('reports')}
        isOpen={state.reports}
        sidebarIsOpen={open}
        name="td.menu.reports"
        icon={<Icon>equalizer_icon</Icon>}
        dense={dense}
      >
        <MenuItemLink
          to="/reports"
          primaryText={resourcesSpec.reports.title}
          leftIcon={<resourcesSpec.reports.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to="/pricing_configs"
          primaryText={resourcesSpec.pricing_configs.title}
          leftIcon={<resourcesSpec.pricing_configs.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      <MenuItemLink
        to="/team"
        primaryText={resourcesSpec.team.title}
        leftIcon={<resourcesSpec.team.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        dense={dense}
        leftIcon={<resourcesSpec.downloads.icon />}
        onClick={onMenuClick}
        primaryText={resourcesSpec.downloads.title}
        sidebarIsOpen={open}
        to="/downloads"
      />
      <SubMenu
        dense={dense}
        handleToggle={() => handleToggle('integrations')}
        icon={<Icon>swap_horiz</Icon>}
        isOpen={state.integrations}
        name="td.menu.integrations"
        sidebarIsOpen={open}
      >
        <MenuItemLink
          to="/integrations-availability"
          primaryText={resourcesSpec.integrations_availability.title}
          leftIcon={<resourcesSpec.integrations_availability.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          dense={dense}
          leftIcon={<Icon>call_received</Icon>}
          onClick={onMenuClick}
          primaryText={resourcesSpec.codat_webhook_calls.title}
          sidebarIsOpen={open}
          to="/codat_webhook_calls"
        />
      </SubMenu>
      <MenuItemLink
        dense={dense}
        leftIcon={<resourcesSpec.settings.icon />}
        onClick={onMenuClick}
        primaryText={resourcesSpec.settings.title}
        sidebarIsOpen={open}
        to="/settings/general"
      />
      <MenuItemLink
        dense={dense}
        leftIcon={<resourcesSpec.banners.icon />}
        onClick={onMenuClick}
        primaryText={resourcesSpec.banners.title}
        sidebarIsOpen={open}
        to="/banners"
      />
      <MenuItemLink
        dense={dense}
        leftIcon={<resourcesSpec.blocked_email_domains.icon />}
        onClick={onMenuClick}
        primaryText={resourcesSpec.blocked_email_domains.title}
        sidebarIsOpen={open}
        to="/blocked-email-domains"
      />
      <SubMenu
        dense={dense}
        handleToggle={() => handleToggle('legal')}
        icon={<Icon>local_police</Icon>}
        isOpen={state.legal}
        name="td.menu.legal"
        sidebarIsOpen={open}
      >
        <MenuItemLink
          dense={dense}
          leftIcon={<resourcesSpec.user_aml_checks.icon />}
          onClick={onMenuClick}
          primaryText={resourcesSpec.user_aml_checks.title}
          sidebarIsOpen={open}
          to="/aml-checks"
        />
        <MenuItemLink
          dense={dense}
          leftIcon={<resourcesSpec.user_card_aor_contracts.icon />}
          onClick={onMenuClick}
          primaryText={resourcesSpec.user_card_aor_contracts.title}
          sidebarIsOpen={open}
          to="/aor-contracts"
        />
        <MenuItemLink
          dense={dense}
          leftIcon={<resourcesSpec.classification_questionnaire.icon />}
          onClick={onMenuClick}
          primaryText={resourcesSpec.classification_questionnaire.title}
          sidebarIsOpen={open}
          to="/classification-questionnaire"
        />
      </SubMenu>
      <MenuItemLink
        dense={dense}
        leftIcon={<resourcesSpec.background_jobs.icon />}
        onClick={onMenuClick}
        primaryText={resourcesSpec.background_jobs.title}
        sidebarIsOpen={open}
        to="/background-jobs"
      />
      {isXSmall && logout}
    </div>
  );
};

export default Menu;
