import PropTypes from 'prop-types';
import qs from 'query-string';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Tab, Tabs } from '@material-ui/core';

import { ORGANIZATION_FEATURE_USAGE_TABS } from 'admin/assets/js/constants';
import US1099FilingsTab from 'admin/assets/js/resources/organizations/components/feature-usage/US1099FilingsTab.jsx';
import ESignaturesTab from 'admin/assets/js/resources/organizations/components/feature-usage/ESignaturesTab.jsx';
import IdentityChecksTab from 'admin/assets/js/resources/organizations/components/feature-usage/IdentityChecksTab.jsx';
import TINMatchingTab from 'admin/assets/js/resources/organizations/components/feature-usage/TINMatchingTab.jsx';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';

const FeatureUsageTab = ({ history, location: { pathname, search }, organization }) => {
  const { tab = ORGANIZATION_FEATURE_USAGE_TABS.E_SIGNATURES } = qs.parse(search);
  return (
    <>
      <Tabs
        className="mb-2"
        onChange={(event, newTab) => {
          history.push(`${pathname}?tab=${newTab}`);
        }}
        value={tab}
      >
        <Tab label="e-signatures" value={ORGANIZATION_FEATURE_USAGE_TABS.E_SIGNATURES} />
        <Tab label="TIN matching" value={ORGANIZATION_FEATURE_USAGE_TABS.TIN_MATCHING} />
        <Tab label="1099 filings" value={ORGANIZATION_FEATURE_USAGE_TABS['1099_FILINGS']} />
        <Tab label="Identity checks" value={ORGANIZATION_FEATURE_USAGE_TABS.IDENTITY_CHECKS} />
      </Tabs>
      {tab === ORGANIZATION_FEATURE_USAGE_TABS.E_SIGNATURES && (
        <ESignaturesTab organization={organization} />
      )}
      {tab === ORGANIZATION_FEATURE_USAGE_TABS.TIN_MATCHING && (
        <TINMatchingTab organization={organization} />
      )}
      {tab === ORGANIZATION_FEATURE_USAGE_TABS['1099_FILINGS'] && (
        <US1099FilingsTab organization={organization} />
      )}
      {tab === ORGANIZATION_FEATURE_USAGE_TABS.IDENTITY_CHECKS && (
        <IdentityChecksTab organization={organization} />
      )}
    </>
  );
};

FeatureUsageTab.propTypes = {
  history: routerHistorySpec.isRequired,
  location: PropTypes.object.isRequired,
  organization: PropTypes.object,
};

FeatureUsageTab.defaultProps = {
  organization: {},
};

export default withRouter(FeatureUsageTab);
