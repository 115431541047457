import React, { useCallback } from 'react';
import {
  Datagrid,
  downloadCSV,
  FunctionField,
  List,
  TextField,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';

import { CURRENCY_LABEL } from 'core/assets/js/constants';
import {
  INVOICING_MODE_LABEL,
  TRANSACTION_MODE_RC_LABEL,
} from 'finance/assets/js/constants';
import { ORGANIZATION_STATUS } from 'organizations/assets/js/constants';
import ListFilter from 'admin/assets/js/resources/pricing_configs/ListFilter.jsx';


const PricingConfigList = (props) => {
  // custom export
  const exporter = useCallback((orgs) => {
    const rows = orgs.map((o) => {
      // precalc for row
      const scale = Object.keys(o.invoicing_fee_scheme.processing?.scale || {})
        .map(k => `${k}: ${o.invoicing_fee_scheme.processing.scale[k]}`)
        .join('\n');
      const pws = o.invoicing_fee_scheme.processing?.perApprovedWorksheet ?? '0.00';

      // note, we need to convert numbers to strings to allow 0s to be visible in the csv
      return {
        Id: o.id,
        Name: o.name,
        Parent: o.invoicing_parent_org_id || '-',
        'Org Ccy': CURRENCY_LABEL[o.currency],
        'Inv Ccy': CURRENCY_LABEL[o.invoicing_target_currency],
        'Inv Mode': INVOICING_MODE_LABEL[o.invoicing_mode],
        'Transaction Mode': TRANSACTION_MODE_RC_LABEL[o.invoicing_transaction_mode],
        'FX Margin': `${o.invoicing_fx_markup}`,
        Terms: `${o.invoicing_terms.gracePeriod} days / ${o.invoicing_terms.terms}`,
        Floor: o.invoicing_fee_scheme.processing?.floor,
        'Processing Fees': `scale - ${scale} / pws - ${pws}`,
        'Base Licence': o.invoicing_fee_scheme.licence?.baseLicenceFee ?? '-',
        'Manager Seats': `${o.invoicing_fee_scheme.licence?.baseManagerSeats ?? '-'}`,
        'Manager Fee': o.invoicing_fee_scheme.licence?.perManagerSeat ?? '-',
        'Provider Seats': `${o.invoicing_fee_scheme.licence?.baseProviderSeats ?? '-'}`,
        'Provider Fee': o.invoicing_fee_scheme.licence?.perProviderSeat ?? '-',
        AOR: o.invoicing_fee_scheme.licence?.aor?.perAORSeat ?? '0.00',
        'E-Sign': o.invoicing_fee_scheme.licence?.esign?.basePrice ?? '0.00',
        TIN: o.invoicing_fee_scheme.licence?.tin?.basePrice ?? '0.00',
        1099: o.invoicing_fee_scheme.licence?.ten99?.basePrice ?? '0.00',
      };
    });

    jsonExport(rows, {
      headers: [
        'Id', 'Name', 'Org Ccy', 'Inv Ccy', 'Inv Mode', 'Transaction Mode',
        'FX Margin', 'Terms', 'Floor', 'Processing Fees',
        'Base Licence', 'Manager Seats', 'Manager Fee', 'Provider Seats',
        'Provider Fee', 'AOR', 'E-Sign', 'TIN', '1099',
      ],
    }, (_, csv) => {
      // note - flag file as utf8, not ascii, by inserting BOM at start of file
      downloadCSV(`\uFEFF${csv}`, 'pricing-configs');
    });
  }, []);

  return (
    <List
      {...props}
      filters={<ListFilter />}
      bulkActionButtons={false}
      sort={{ field: 'name', order: 'ASC' }}
      filterDefaultValues={{ status: ORGANIZATION_STATUS.ACTIVE }}
      exporter={exporter}
    >
      <Datagrid
        hover={false}
        rowStyle={({ invoicing_fee_scheme, invoicing_parent_org_id }) => {
          // colour row bg red if licencing disabled
          if (invoicing_fee_scheme.licence?.disableLicenceFeeValidation) {
            return { backgroundColor: '#FBEBEC' };
          }
          // color row bg yellow if child org
          if (invoicing_parent_org_id) {
            return { backgroundColor: '#fbfbe0' };
          }
          return null;
        }}
      >
        <TextField label="id" source="id" />
        <TextField label="Name" source="name" />
        <TextField label="Parent" source="invoicing_parent_org_id" emptyText="-" />
        <FunctionField
          label="Org Ccy"
          source="currency"
          render={({ currency }) => CURRENCY_LABEL[currency]}
        />
        <FunctionField
          label="Inv Ccy"
          source="invoicing_target_currency"
          render={({ invoicing_target_currency }) => CURRENCY_LABEL[invoicing_target_currency]}
        />
        <FunctionField
          label="Inv Mode"
          source="invoicing_mode"
          render={({ invoicing_mode }) => INVOICING_MODE_LABEL[invoicing_mode]}
        />
        <FunctionField
          label="Transaction Mode"
          source="invoicing_transaction_mode"
          render={
            ({ invoicing_transaction_mode }) => TRANSACTION_MODE_RC_LABEL[
              invoicing_transaction_mode
            ]
          }
        />
        <TextField label="FX Margin" source="invoicing_fx_markup" />
        <FunctionField
          label="Terms"
          source="invoicing_grace_period"
          render={({ invoicing_terms }) => `${invoicing_terms.gracePeriod} days / ${invoicing_terms.terms}`}
        />
        <FunctionField
          label="Floor"
          source="invoicing_fee_scheme_dump->>'$.processing.floor'"
          render={({ invoicing_fee_scheme }) => `${invoicing_fee_scheme.processing?.floor}`}
        />
        <FunctionField
          label="Processing fees"
          render={({ invoicing_fee_scheme }) => {
            const scale = Object.keys(invoicing_fee_scheme.processing?.scale || {})
              .map(k => `${k}: ${invoicing_fee_scheme.processing.scale[k]}`)
              .join('\n');
            const pws = invoicing_fee_scheme.processing?.perApprovedWorksheet ?? '0.00';
            return `scale - ${scale} / pws - ${pws}`;
          }}
        />
        <FunctionField
          label="Base Licence"
          source="invoicing_fee_scheme_dump->>'$.licence.baseLicenceFee'"
          render={({ invoicing_fee_scheme }) => `${invoicing_fee_scheme.licence?.baseLicenceFee ?? '-'}`}
        />
        <FunctionField
          label="Manager Seats"
          source="invoicing_fee_scheme_dump->>'$.licence.baseManagerSeats'"
          render={({ invoicing_fee_scheme }) => `${invoicing_fee_scheme.licence?.baseManagerSeats ?? '-'}`}
        />
        <FunctionField
          label="Manager Fee"
          source="invoicing_fee_scheme_dump->>'$.licence.perManagerSeat'"
          render={({ invoicing_fee_scheme }) => `${invoicing_fee_scheme.licence?.perManagerSeat ?? '-'}`}
        />
        <FunctionField
          label="Provider Seats"
          source="invoicing_fee_scheme_dump->>'$.licence.baseProviderSeats'"
          render={({ invoicing_fee_scheme }) => `${invoicing_fee_scheme.licence?.baseProviderSeats ?? '-'}`}
        />
        <FunctionField
          label="Provider Fee"
          source="invoicing_fee_scheme_dump->>'$.licence.perProviderSeat'"
          render={({ invoicing_fee_scheme }) => `${invoicing_fee_scheme.licence?.perProviderSeat ?? '-'}`}
        />

        <FunctionField
          label="AOR"
          source="invoicing_fee_scheme_dump->>'$.licence.aor.perAORSeat'"
          render={({ invoicing_fee_scheme }) => `${invoicing_fee_scheme.licence?.aor?.perAORSeat ?? '0.00'}`}
        />
        <FunctionField
          label="E-Sign"
          source="invoicing_fee_scheme_dump->>'$.licence.esign.basePrice'"
          render={({ invoicing_fee_scheme }) => `${invoicing_fee_scheme.licence?.esign?.basePrice ?? '0.00'}`}
        />
        <FunctionField
          label="TIN"
          source="invoicing_fee_scheme_dump->>'$.licence.tin.basePrice'"
          render={({ invoicing_fee_scheme }) => `${invoicing_fee_scheme.licence?.tin?.basePrice ?? '0.00'}`}
        />
        <FunctionField
          label="1099"
          source="invoicing_fee_scheme_dump->>'$.licence.ten99.basePrice'"
          render={({ invoicing_fee_scheme }) => `${invoicing_fee_scheme.licence?.ten99?.basePrice ?? '0.00'}`}
        />
      </Datagrid>
    </List>
  );
};

export default PricingConfigList;
