import React from 'react';
import { Link } from 'react-router-dom';
import {
  Datagrid,
  Filter,
  FunctionField,
  List,
  SelectInput,
  TextField,
} from 'react-admin';

const startYear = 2016;
const currentYear = new Date().getFullYear();

const yearsOptions = Array.from(
  { length: currentYear - startYear + 1 },
  (_, i) => ({ id: startYear + i, name: startYear + i }),
).reverse();

const ListFilter = props => (
  <Filter {...props}>
    <SelectInput
      alwaysOn
      allowEmpty={false}
      label="Year"
      source="targetYear"
      choices={yearsOptions}
      translateChoice={false}
    />
  </Filter>
);

// eslint-disable-next-line react/no-multi-comp
const SignNowUsage = ({ ...props }) => (
  <List
    {...props}
    bulkActionButtons={false}
    className="mt-3"
    filters={<ListFilter />}
    filterDefaultValues={{
      targetYear: currentYear,
    }}
  >
    <Datagrid>
      <FunctionField
        label="Organization"
        render={({ id: orgId, orgName }) => {
          if (!orgId || !orgName) {
            return '';
          }
          return (
            <Link to={`/organizations/${orgId}/show`}>{orgName}</Link>
          );
        }}
        source="last_invoice_date"
      />
      <TextField label="Jan" source="usage.jan" />
      <TextField label="Feb" source="usage.feb" />
      <TextField label="Mar" source="usage.mar" />
      <TextField label="Apr" source="usage.apr" />
      <TextField label="May" source="usage.may" />
      <TextField label="Jun" source="usage.jun" />
      <TextField label="Jul" source="usage.jul" />
      <TextField label="Aug" source="usage.aug" />
      <TextField label="Sep" source="usage.sep" />
      <TextField label="Oct" source="usage.oct" />
      <TextField label="Nov" source="usage.nov" />
      <TextField label="Dec" source="usage.dec" />
    </Datagrid>
  </List>
);

export default SignNowUsage;
