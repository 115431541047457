import PropTypes from 'prop-types';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { Button } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

import ApiTable from 'admin/assets/js/components/ApiTable.jsx';
import BackButton from 'admin/assets/js/components/BackButton.jsx';
import { ORGANIZATION_FEATURE_USAGE_TABS } from 'admin/assets/js/constants';
import { formatDate } from 'core/assets/js/lib/utils';
import {
  SMART_SEARCH_RESULT_COLOR, SMART_SEARCH_RESULT_LABEL,
} from 'organizations/assets/js/constants';

const thisFormatDate = date => formatDate(`${date}-01`, 'MMMM YYYY');

const tab = ORGANIZATION_FEATURE_USAGE_TABS.IDENTITY_CHECKS;

export const IdentityChecksTab = ({ organization }) => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { date: selectedDate } = qs.parse(search);

  const resource = 'identity_checks';

  if (selectedDate) {
    return (
      <>
        <div className="d-flex align-items-center justify-content-left">
          <BackButton />
          <h2 className="m-0">{thisFormatDate(selectedDate)}</h2>
        </div>
        <ApiTable
          columns={[
            {
              label: 'ID',
              name: 'id',
              options: {
                filter: false,
                sort: true,
              },
            },
            {
              label: 'User',
              name: 'user_name',
              options: {
                cellRender: (userName, check) => (
                  !check.user_id ? userName : (
                    <Link to={`/full_users/${check.user_id}/show?backUrl=${pathname}${search}`}>
                      {userName}
                    </Link>
                  )
                ),
                downloadCellRender: userName => userName,
                filter: false,
                sort: true,
              },
            },
            {
              label: 'Created at',
              name: 'created_at',
              options: {
                cellRender: createdAt => formatDate(createdAt),
                filter: false,
                sort: true,
              },
            },
            {
              label: 'Smart Search ID',
              name: 'smart_search_id',
              options: {
                filter: false,
                sort: true,
              },
            },
            {
              label: 'Result',
              name: 'result',
              options: {
                cellRender: result => (
                  !result ? '' : (
                    <Chip
                      label={SMART_SEARCH_RESULT_LABEL[result]}
                      size="small"
                      style={{ backgroundColor: SMART_SEARCH_RESULT_COLOR[result], color: 'white' }}
                    />
                  )
                ),
                filter: false,
                sort: true,
              },
            },
            {
              label: 'Updated at',
              name: 'updated_at',
              options: {
                cellRender: updatedAt => formatDate(updatedAt),
                filter: false,
                sort: true,
              },
            },
            {
              label: 'Expired at',
              name: 'expired_at',
              options: {
                cellRender: expiredAt => formatDate(expiredAt),
                filter: false,
                sort: true,
              },
            },
          ]}
          defaultSortBy="created_at"
          defaultSortDirection="DESC"
          extraResourceQueryParams={{ date: selectedDate, organization_id: organization.id, tab }}
          resource={resource}
        />
      </>
    );
  }

  return (
    <ApiTable
      columns={[
        {
          label: 'Date',
          name: 'date',
          options: {
            cellRender: thisFormatDate,
            filter: false,
            sort: true,
          },
        },
        {
          label: 'Created',
          name: 'created',
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          label: 'Passed',
          name: 'passed',
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          label: 'Failed',
          name: 'failed',
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          label: 'Pending',
          name: 'pending',
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          label: 'Expired',
          name: 'expired',
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          label: 'Details',
          name: 'date',
          options: {
            cellRender: date => (
              <Button
                color="primary"
                onClick={() => {
                  history.push(`${pathname}?date=${date}&tab=${tab}`);
                }}
              >
                View
              </Button>
            ),
            filter: false,
            sort: false,
          },
        },
      ]}
      defaultSortBy="date"
      defaultSortDirection="DESC"
      excludeColumnsFromDownload={['Details']}
      extraResourceQueryParams={{ organization_id: organization.id, tab }}
      noMatchText="No identity checks usage by this organisation"
      resource={resource}
      searchEnabled={false}
    />
  );
};

IdentityChecksTab.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default IdentityChecksTab;
