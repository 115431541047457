import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';

import httpClient from 'admin/assets/js/lib/httpClient';
import { getNotifications } from 'admin/assets/js/lib/notifications.jsx';
import { formatDate } from 'admin/assets/js/lib/utils';
import UserAmlInfo from 'admin/assets/js/resources/full_users/UserAmlInfo.jsx';
import { WINDOW_RELOAD } from 'core/assets/js/config/settings';
import { USER_STATUS_LABEL } from 'core/assets/js/constants';
import { USER_STATUS_COLORS } from 'admin/assets/js/resources/full_users/constants';
import { adminDisable2faApiUrl, adminEmail2faRecoveryCodesApiUrl } from 'admin/urls';
import {
  COMPANY_TAX_SYSTEM_INFO,
} from 'settings/assets/js/constants';

const useStyles = makeStyles({
  labelStyle: {
    color: '#0000008A',
    marginBottom: '5px',
  },
});

const EDIT_2FA_OPTIONS = {
  EMAIL_RECOVERY_CODES: 'email-recovery-codes',
  DISABLE: 'disable',
};

const UserInfo = ({ record: user }) => {
  const [edit2faModalIsOpen, setEdit2faModalIsOpen] = useState(false);
  const [disable2faReason, setDisable2faReason] = useState('');
  const [edit2faOption, setEdit2faOption] = useState(EDIT_2FA_OPTIONS.EMAIL_RECOVERY_CODES);
  const notifications = getNotifications();

  const classes = useStyles();
  const userTaxSystemType = user?.company?.tax_system_type;
  const userIsOrgMember = !isEmpty(user.userCards);
  const userCountry = user.profile?.address_components?.country;
  const userHasProfile = user.profile;

  if (!userHasProfile) {
    return (
      <Paper style={{ padding: '15px' }}>
        User has no profile setup
      </Paper>
    );
  }

  const edit2faIsDisable = edit2faOption === EDIT_2FA_OPTIONS.DISABLE;

  const closeModal = () => {
    setEdit2faModalIsOpen(false);
    setDisable2faReason('');
    setEdit2faOption(EDIT_2FA_OPTIONS.EMAIL_RECOVERY_CODES);
  };

  return (
    <Paper style={{ padding: '15px' }}>
      <div className={classes.root}>
        <h2 style={{ marginTop: '0', fontWeight: '600' }}>General info</h2>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <div className={classes.labelStyle}>Name</div>
            <div>
              {`${user.profile.firstName} ${user.profile.lastName}`}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.labelStyle}>Status</div>
            <div style={{ color: USER_STATUS_COLORS[user.status] }}>
              {USER_STATUS_LABEL[user.status]}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.labelStyle}>Tax registered</div>
            <div>
              {COMPANY_TAX_SYSTEM_INFO[userTaxSystemType]?.label || 'None'}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.labelStyle}>Created</div>
            <div>
              {formatDate(user.created_at)}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.labelStyle}>Organizations</div>
            { userIsOrgMember ? (
              <div>
                {user.userCards.length || ''}
                {' ('}
                { user.userCards.map((uc, index) => (
                  <span key={uc.id}>
                    {' '}
                    <Link to={`/organizations/${uc.organization.id}/show`}>{uc.organization.name}</Link>
                    {index !== user.userCards.length - 1 ? ', ' : ''}
                  </span>
                ))}
                {' )'}
              </div>
            ) : '-'}
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.labelStyle}>Tax number</div>
            <div>
              {userTaxSystemType ? user?.company[COMPANY_TAX_SYSTEM_INFO[userTaxSystemType].systemLabel] : '-'}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.labelStyle}>Country</div>
            <div>
              {userCountry || '-'}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.labelStyle}>Full legal name</div>
            <div>
              {`${user.profile.firstName} ${user.profile.middleName || ''} ${user.profile.lastName}`}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.labelStyle}>2FA</div>
            <div>
              {!user.has2faEnabled && 'Disabled'}
              {user.has2faEnabled && (
                <>
                  Enabled (
                  <a className="imitate-link" onClick={() => setEdit2faModalIsOpen(true)}>Edit</a>
                  )
                  <Dialog
                    keepMounted
                    maxWidth="sm"
                    onClose={() => setEdit2faModalIsOpen(false)}
                    open={edit2faModalIsOpen}
                  >
                    <DialogTitle>Edit 2FA</DialogTitle>
                    <DialogContent>
                      <FormControl>
                        <FormLabel>Choose one of these options:</FormLabel>
                        <RadioGroup
                          onChange={event => setEdit2faOption(event.target.value)}
                          value={edit2faOption}
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Email recovery codes"
                            value={EDIT_2FA_OPTIONS.EMAIL_RECOVERY_CODES}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Disable 2FA"
                            value={EDIT_2FA_OPTIONS.DISABLE}
                          />
                        </RadioGroup>
                      </FormControl>
                      {edit2faIsDisable && (
                        <TextField
                          className="w-100"
                          error={!disable2faReason}
                          helperText={!disable2faReason && 'Please enter a reason'}
                          label="Reason"
                          multiline
                          name="reason"
                          onChange={event => setDisable2faReason(event.target.value)}
                          placeholder="Reason"
                          required
                          rows={4}
                          value={disable2faReason}
                          variant="filled"
                        />
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button color="primary" onClick={closeModal}>
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        disabled={edit2faIsDisable && !disable2faReason}
                        onClick={async () => {
                          const urlFn = edit2faIsDisable
                            ? adminDisable2faApiUrl
                            : adminEmail2faRecoveryCodesApiUrl;
                          try {
                            await httpClient(
                              urlFn(user.id),
                              {
                                body: JSON.stringify(
                                  edit2faIsDisable ? { reason: disable2faReason } : {},
                                ),
                                method: 'POST',
                              },
                            );
                            if (edit2faIsDisable) {
                              WINDOW_RELOAD();
                              return;
                            }
                            closeModal();
                            notifications.success(`Recovery codes emailed to ${user.email}`);
                          } catch (e) {
                            notifications.error(e.response?.data?._error || e.message);
                          }
                        }}
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.labelStyle}>Email</div>
            <div>
              {user.email}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.labelStyle}>US citizen</div>
            {
              userCountry ? (
                `${user.profile?.address_components?.country_code === 'US' ? 'Yes' : 'No'}`
              ) : '-'
            }
          </Grid>
        </Grid>
        <h2 className="mt-5" style={{ fontWeight: '600' }}>AML</h2>
        <UserAmlInfo user={user} />
      </div>
    </Paper>
  );
};

UserInfo.propTypes = {
  record: PropTypes.object,
};

UserInfo.defaultProps = {
  record: {},
};

export default UserInfo;
