import React from 'react';
import {
  Filter,
  SelectInput, TextInput,
} from 'react-admin';
import { ORGANIZATION_STATUS_LABEL } from 'organizations/assets/js/constants';
import { INVOICING_MODE, TRANSACTION_MODE, TRANSACTION_MODE_SHORT_LABEL } from 'finance/assets/js/constants';


const statusOptions = Object.keys(ORGANIZATION_STATUS_LABEL).map(k => ({
  id: k, name: ORGANIZATION_STATUS_LABEL[k],
}));

const invoiceModeOptions = Object.keys(INVOICING_MODE).map(k => ({
  id: k, name: INVOICING_MODE[k],
}));

const transactionModeOptions = Object.keys(TRANSACTION_MODE).map(k => ({
  id: k, name: TRANSACTION_MODE_SHORT_LABEL[TRANSACTION_MODE[k]],
}));

const managerSeatOptions = [{
  id: 'no_seats',
  name: 'No manager seats pricing',
}];

const baseFeeOptions = [{
  id: 'no_base_fee',
  name: 'No licence base fee',
}];

const ListFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <SelectInput
      label="Invoice mode"
      alwaysOn
      allowEmpty
      source="invoicing_mode"
      choices={invoiceModeOptions}
    />
    <SelectInput
      label="Transaction mode"
      alwaysOn
      allowEmpty
      source="invoicing_transaction_mode"
      choices={transactionModeOptions}
    />
    <SelectInput
      label="Status"
      alwaysOn
      allowEmpty
      source="status"
      choices={statusOptions}
    />
    <SelectInput
      label="Licence fees"
      alwaysOn
      allowEmpty
      source="base_fee"
      choices={baseFeeOptions}
    />
    <SelectInput
      label="Manager seats"
      alwaysOn
      allowEmpty
      source="manager_seats"
      choices={managerSeatOptions}
    />
  </Filter>
);

export default ListFilter;
