import Big from 'big.js';
import { isNil } from 'lodash';
import { getRate, BANK_CURRENCY, Money } from 'td-finance-ts';

import { assertAllKeysPresent } from 'core/assets/js/lib/utils';
import {
  TRANSACTION_MODE, TRANSACTION_METHOD, SEPA_COUNTRIES, SYSTEM_CURRENCY,
  PAYONEER_BASE_FEE_USD,
} from 'finance/assets/js/constants';
import InvoiceAmounts from 'finance/assets/js/lib/InvoiceAmounts';
import TransactionAction from 'finance/assets/js/lib/TransactionAction';
import { convertsTo } from 'finance/assets/js/lib/utils';
import InvoiceRateMap from 'finance/assets/js/lib/InvoiceRateMap';
import TransactionRateMap from 'finance/assets/js/lib/TransactionRateMap';
import { auditTransactionAmounts, parseSerializedTransactionInvoiceAmounts } from './transaction-mode-utils';

Big.RM = 1;
Big.DP = 2;

/**
 * Transaction amounts pojo.
 */
class TransactionAmounts {
  static fromGenericTransaction({ totalFee, ...rest }) {
    if (isNil(totalFee)) {
      throw new Error('fee is required');
    }

    return new TransactionAmounts({ totalFee, ...rest });
  }

  static fromAction({
    transactionAction, invoiceAmounts, transactionMode,
    currentBalanceRates,
  } = {}) {
    let rates;
    if (currentBalanceRates) {
      rates = invoiceAmounts.pickBalanceFXRates({
        sourceCurrency: transactionAction.getOutgoingCurrency(),
        targetCurrency: transactionAction.getTargetCurrency(),
        currentBalanceRates,
        transactionRate: transactionAction.getTargetRate(),
      });
      if (transactionAction.getTargetRate() !== rates.balanceToTargetRate) {
        throw new Error('mismatching rates');
      }
    }
    const params = {
      transactionMode,
      invoiceAmounts,
      ...transactionAction.serialize(),
      rates,
    };

    return new TransactionAmounts(params);
  }

  static fromExpectedTransactionAction({
    transactionMode,
    expectedTransactionAction,
    rateMapAtTransactionTime,
    serializedInvoiceAmounts: invoiceAmounts,
  }) {
    assertAllKeysPresent({
      transactionMode,
      invoiceAmounts,
      expectedTransactionAction,
      rateMapAtTransactionTime,
    });

    return new TransactionAmounts({
      transactionMode,
      rates: rateMapAtTransactionTime.serialize(),
      invoiceAmounts,
      ...expectedTransactionAction.serialize(),
      fee: expectedTransactionAction.getTotalFee(),
    });
  }

  static zero(amounts) {
    const invoiceAmounts = amounts instanceof InvoiceAmounts
      ? amounts
      : new InvoiceAmounts(amounts);

    const transactionAction = TransactionAction.zero({
      outgoingCurrency: invoiceAmounts.getOrgCurrency(),
      targetCurrency: invoiceAmounts.getTargetCurrency(),
    });

    const params = {
      invoiceAmounts,
      ...transactionAction.serialize(),
    };

    return new TransactionAmounts(params);
  }

  static fromMultipleInvoiceAmounts(allAmounts, { fee = '0.00', transactionMode } = {}) {
    const {
      invoiceMoney,
      balanceMoney,
      targetMoney,
      rateMapAtInvoiceTime,
    } = InvoiceRateMap.aggregateInvoiceAmounts(allAmounts);

    const serializedInvoiceAmounts = {
      ...rateMapAtInvoiceTime.serialize(),
      total: invoiceMoney.toString(),
    };

    const targetRate = balanceMoney.getRate(targetMoney);

    const params = {
      transactionMode,
      invoiceAmounts: serializedInvoiceAmounts,
      outgoingCurrency: balanceMoney.getCurrency(),
      outgoingAmount: balanceMoney.add(fee).toString(),
      totalFee: fee,
      targetAmount: targetMoney.toString(),
      targetCurrency: targetMoney.getCurrency(),
      targetRate,
    };

    return new TransactionAmounts(params);
  }

  static fromInvoiceAmounts(amounts, { fee = '0.00', transactionMode } = {}) {
    const invoiceAmounts = amounts instanceof InvoiceAmounts
      ? amounts
      : new InvoiceAmounts(amounts);

    const total = invoiceAmounts.getBalanceTotal();
    const currency = invoiceAmounts.getBalanceCurrency();
    const targetCurrency = invoiceAmounts.getTargetCurrency();
    const targetTotal = currency === targetCurrency ? total : invoiceAmounts.getTargetTotal();

    const targetRate = new Money(total, currency).getRate(new Money(targetTotal, targetCurrency));

    if (!convertsTo(total, targetRate, targetTotal)) {
      throw new Error('Conversion failed');
    }

    const params = {
      transactionMode,
      invoiceAmounts,
      outgoingCurrency: currency,
      outgoingAmount: new Money(total, currency).add(fee).toString(),
      totalFee: fee,
      targetAmount: targetTotal,
      targetCurrency,
      targetRate,
    };

    return new TransactionAmounts(params);
  }

  static createFromFormData({ formData, invoiceAmounts: serializedInvoiceAmounts, exchangeRates }) {
    if (!formData.amounts) {
      throw new Error('form data has no amounts');
    }
    const invoiceAmounts = serializedInvoiceAmounts instanceof InvoiceAmounts
      ? serializedInvoiceAmounts
      : new InvoiceAmounts(serializedInvoiceAmounts);

    const {
      currency: outgoingCurrency, outgoingAmount: formOutgoingAmount,
    } = formData.amounts.inBalanceCurrency;

    let outgoingAmount = new Money(formOutgoingAmount, outgoingCurrency).toString();
    const { targetRate: exchangeRate } = formData.amounts;
    const { method, countryCode } = formData;

    let netOutgoingAmount;
    let totalFee;
    if ([
      TRANSACTION_METHOD.WORLDPAY,
    ].includes(method)) {
      totalFee = '0.00';
      netOutgoingAmount = new Money(
        formData.amounts.inBalanceCurrency.netOutgoingAmount,
        outgoingCurrency,
      ).toString();
    } else {
      totalFee = new Money(
        formData.amounts.inBalanceCurrency.totalFee,
        outgoingCurrency,
      ).toString();
      netOutgoingAmount = new Money(outgoingAmount, outgoingCurrency).sub(totalFee).toString();
    }

    switch (method) {
      case TRANSACTION_METHOD.WORLDPAY:
        {
          // based on Worldpay fee structure ( 9/11/2021 )
          // note these are based on destination country, not currency
          // UK pay outs - 0.40 gbp
          // Euro region pay outs - 1.25 gbp
          // International payments - 6.25 gbp
          // note - the exchange rate used to handle currency conversion for these amounts is based
          //        on TrW exchange rates at the time the modal data is entered, this may not match
          //        the exchange rate used by Worldpay at the time of the transaction ( which is
          //        not available to us )

          // get exchange rate to convert Worldpay fee ( gbp ) to outgoing currency
          const sanitizedOutgoingCurrency = (outgoingCurrency || '').toUpperCase();
          const feeExchangeRate = exchangeRates && exchangeRates[sanitizedOutgoingCurrency]
            ? exchangeRates[sanitizedOutgoingCurrency] : 1.0;

          // set fee based on target country of transfer
          if (countryCode === 'GB') {
            totalFee = Big(0.40).times(feeExchangeRate).toFixed(2);
          } else if (SEPA_COUNTRIES.includes(countryCode)) {
            totalFee = Big(1.25).times(feeExchangeRate).toFixed(2);
          } else {
            totalFee = Big(6.25).times(feeExchangeRate).toFixed(2);
          }

          // recalc outgoing amount to include fee
          outgoingAmount = new Money(netOutgoingAmount, outgoingCurrency).add(totalFee).toString();
        }
        break;
      case TRANSACTION_METHOD.PAYONEER:
        if (outgoingCurrency !== 'usd') {
          throw new Error('can only record payoneer fees in usd');
        }
        // https://talentdesk.atlassian.net/browse/PAYM-297
        totalFee = PAYONEER_BASE_FEE_USD;
        break;
      default:
        if (netOutgoingAmount) {
          totalFee = new Money(outgoingAmount, outgoingCurrency).sub(netOutgoingAmount).toString();
        }
        break;
    }

    const targetTotal = invoiceAmounts.getTargetTotal();
    const targetCurrency = invoiceAmounts.getTargetCurrency();

    let targetRate;
    switch (method) {
      case TRANSACTION_METHOD.REALNET:
      case TRANSACTION_METHOD.STRIPE:
        targetRate = exchangeRate;
        break;
      default:
        ({ rate: targetRate } = getRate(
          new Money(outgoingAmount, outgoingCurrency).sub(totalFee).toString(),
          outgoingCurrency,
          new Money(targetTotal, targetCurrency).toString(),
          targetCurrency,
        ));
        break;
    }

    const params = {
      invoiceAmounts,
      outgoingCurrency,
      outgoingAmount,
      totalFee,
      targetAmount: new Money(outgoingAmount, outgoingCurrency)
        .sub(totalFee)
        .convert(targetCurrency, { rate: targetRate })
        .toString(),
      targetCurrency,
      targetRate,
    };

    return new TransactionAmounts(params);
  }

  static fromTransferwiseTransfer({
    invoiceAmounts: amounts,
    fee,
    transactionMode,
    ...transfer
  } = {}) {
    const invoiceAmounts = amounts instanceof InvoiceAmounts
      ? amounts
      : new InvoiceAmounts(amounts);

    if (isNil(transactionMode)) {
      throw new Error('transactionMode is required');
    }
    if (!invoiceAmounts) {
      throw new Error('invoiceAmounts are required');
    }
    const { sourceValue, sourceCurrency, targetValue, targetCurrency, rate } = transfer;
    if (new Money(sourceValue, sourceCurrency).eq(0)
      || new Money(targetValue, targetCurrency).eq(0)) {
      return TransactionAmounts.zero(invoiceAmounts);
    }

    const sourceIsNet = convertsTo(sourceValue, rate, targetValue);

    let totalFee = fee;
    let outgoingAmount;
    if (sourceIsNet) {
      // we don't know what the fee is, we will use any explicitly stated from outside
      totalFee = fee || '0.00';
      outgoingAmount = new Money(sourceValue, sourceCurrency).add(totalFee).toString();
    } else if (!isNil(fee)) {
      // we know the outgoing amount and the fee
      // sourceValue is the total outgoing amount
      outgoingAmount = new Money(sourceValue, sourceCurrency).toString();
      totalFee = new Money(fee, sourceCurrency).toString();
    } else {
      // we have no information on the fee, we will derive that
      const netOutgoingAmount = new Money(targetValue, targetCurrency).div(rate).toString();
      // sourceValue is the total outgoing amount
      outgoingAmount = new Money(sourceValue, sourceCurrency).toString();
      totalFee = new Money(outgoingAmount, sourceCurrency).sub(netOutgoingAmount).toString();
    }

    const params = {
      transactionMode,
      invoiceAmounts,

      outgoingCurrency: sourceCurrency,
      outgoingAmount,
      totalFee,
      targetAmount: sourceValue ? targetValue : '0.00',
      targetCurrency,
      targetRate: rate,
    };

    return new TransactionAmounts(params);
  }

  static fromPayoneerPayout({
    transactionMode,
    invoiceAmounts: amounts,
    payout,
    // payoneer fee is always $3.00
    fee,
    feeCurrency,
    // payoneer payouts are USD to USD
    targetRate = 1,
    targetCurrency = payout.getCurrency(),
  }) {
    if (isNil(fee)) {
      throw new Error('fee is required');
    }

    const invoiceAmounts = amounts instanceof InvoiceAmounts
      ? amounts
      : new InvoiceAmounts(amounts);

    const currency = payout.getCurrency();
    if (feeCurrency && feeCurrency !== currency) {
      throw new Error('trying to record fee in different currency');
    }
    const amount = payout.getAmount();

    const params = {
      transactionMode,
      invoiceAmounts,
      outgoingCurrency: currency,
      outgoingAmount: new Money(amount, currency).add(fee).toString(),
      totalFee: fee,
      targetAmount: new Money(amount, currency).convert(
        targetCurrency,
        { rate: targetRate },
      ).toString(),
      targetCurrency,
      targetRate,
    };

    return new TransactionAmounts(params);
  }

  /**
   * Create an instance from a Worldpay payout
   *
   * @param {TRANSACTION_MODE} transactionMode - the transaction mode
   * @param {InvoiceAmounts} invoiceAmounts - an instance of the invoice amounts
   * @param {WorldPayPayout} payout - a WorldPay payout instance
   * @param {string} fee - the expected fee
   * @param {CURRENCY} feeCurrency - the fee currency
   * @param {Number} targetRate - the expected fx rate
   *
   * @return {TransactionAmounts} an instance of transaction amounts
   */
  static fromWorldPayPayout({
    transactionMode,
    invoiceAmounts: amounts,
    payout,
    fee,
    feeCurrency,
    targetRate,
  }) {
    if (isNil(fee)) {
      throw new Error('fee is required');
    }
    if (isNil(targetRate)) {
      throw new Error('target rate is required');
    }

    const invoiceAmounts = amounts instanceof InvoiceAmounts
      ? amounts
      : new InvoiceAmounts(amounts);

    const outgoingCurrency = payout.getSourceCurrency().toLowerCase();
    const targetCurrency = payout.getTargetCurrency().toLowerCase();

    let netOutgoingMoney = new Money(payout.getSourceAmount(), outgoingCurrency);
    let targetMoney = new Money(payout.getTargetAmount(), targetCurrency);

    if (!netOutgoingMoney.isZero()) {
      targetMoney = netOutgoingMoney.convert(targetCurrency, { rate: targetRate });
    } else if (!targetMoney.isZero()) {
      netOutgoingMoney = targetMoney.convert(outgoingCurrency, { reverseRate: targetRate });
    } else {
      return TransactionAmounts.zero(invoiceAmounts);
    }

    const currency = payout.getSourceCurrency();
    if (feeCurrency && feeCurrency !== currency) {
      throw new Error('trying to record fee in different currency');
    }

    const params = {
      transactionMode,
      invoiceAmounts,
      outgoingCurrency,
      outgoingAmount: netOutgoingMoney.add(fee).toString(),
      totalFee: fee,
      targetAmount: targetMoney.toString(),
      targetCurrency,
      targetRate,
    };

    return new TransactionAmounts(params);
  }

  static fromRevolutPayment({
    transactionMode,
    invoiceAmounts: amounts,
    payment,
  }) {
    const invoiceAmounts = amounts instanceof InvoiceAmounts
      ? amounts
      : new InvoiceAmounts(amounts);

    const {
      sourceAmount, sourceCurrency, fee, targetAmount, targetCurrency,
    } = payment.getFirstPaymentLegDetails();

    const { rate: targetRate } = getRate(
      new Money(sourceAmount, sourceCurrency).toString(),
      sourceCurrency,
      new Money(targetAmount, targetCurrency).toString(),
      targetCurrency,
    );

    const params = {
      transactionMode,
      invoiceAmounts,
      outgoingCurrency: sourceCurrency,
      outgoingAmount: new Money(sourceAmount, sourceCurrency).add(fee).toString(),
      totalFee: fee,
      targetAmount,
      targetCurrency,
      targetRate,
    };

    return new TransactionAmounts(params);
  }

  static fromPaymentOption({
    invoiceAmounts: amounts, transactionMode,
    paymentOption, rate, currentBalanceRates,
  } = {}) {
    const invoiceAmounts = amounts instanceof InvoiceAmounts
      ? amounts
      : new InvoiceAmounts(amounts);

    if (isNil(transactionMode)) {
      throw new Error('transactionMode is required');
    }

    const {
      sourceAmount, sourceCurrency, targetAmount, targetCurrency, fee: { total: totalFee },
    } = paymentOption;

    if (isNil(totalFee)) {
      throw new Error('totalFee is required');
    }

    let rates;
    if (currentBalanceRates) {
      rates = invoiceAmounts.pickBalanceFXRates({
        sourceCurrency,
        targetCurrency,
        currentBalanceRates,
        transactionRate: rate,
      });
      if (rate !== rates.balanceToTargetRate) {
        throw new Error('mismatching rates');
      }
    }

    return new TransactionAmounts({
      transactionMode,
      invoiceAmounts,
      outgoingCurrency: sourceCurrency,
      outgoingAmount: sourceAmount,
      totalFee,
      targetAmount,
      targetCurrency,
      targetRate: rate,
      rates,
    });
  }

  static fromTransferwiseQuote({
    invoiceAmounts: amounts, transactionMode, quote,
    currentBalanceRates,
  } = {}) {
    const invoiceAmounts = amounts instanceof InvoiceAmounts
      ? amounts
      : new InvoiceAmounts(amounts);
    if (isNil(transactionMode)) {
      throw new Error('transactionMode is required');
    }
    const selectedPaymentOption = quote.getSelectedPaymentOption();
    const rate = quote.getRate();

    return TransactionAmounts.fromPaymentOption({
      invoiceAmounts, transactionMode,
      paymentOption: selectedPaymentOption, rate,
      currentBalanceRates,
    });
  }

  static fromTransferwiseAdminForm({ invoiceAmounts: amounts, amount, currency, targetRate }) {
    if (isNil(amount)) {
      throw new Error('amount is required');
    }
    if (isNil(currency)) {
      throw new Error('currency is required');
    }
    if (isNil(targetRate)) {
      throw new Error('target rate is required');
    }
    const invoiceAmounts = amounts instanceof InvoiceAmounts
      ? amounts
      : new InvoiceAmounts(amounts);
    const outgoingAmount = new Money(amount, currency).toString();
    const netOutgoingAmount = invoiceAmounts.getInvoicedMoney();
    const sourceCurrency = currency;

    return new TransactionAmounts({
      invoiceAmounts,
      outgoingCurrency: sourceCurrency,
      outgoingAmount,
      totalFee: new Money(outgoingAmount, sourceCurrency).sub(netOutgoingAmount).toString(),
      targetAmount: new Money(new Money(
        netOutgoingAmount, sourceCurrency,
      ).mul(targetRate), invoiceAmounts.targetCurrency).toString(),
      targetCurrency: invoiceAmounts.targetCurrency,
      targetRate,
    });
  }

  /**
   * Constructor.
   * @param  {...any} args - instance values.
   */
  constructor(...args) {
    this.init(...args);
  }

  /**
   * Initialise instance with initial values.
   * @param {object} param0 - instance values.
   */
  init({
    outgoingCurrency,
    outgoingAmount,
    totalFee,
    targetCurrency,
    targetRate,
    targetAmount,
    invoiceAmounts,
    rates = {},
    transactionMode = TRANSACTION_MODE.FIXED_BALANCE,
  } = {}) {
    if (isNil(outgoingAmount)) {
      throw new Error('outgoing amount is required');
    }

    if (!outgoingCurrency) {
      throw new Error('outgoing currency is required');
    }

    if (!Object.values(BANK_CURRENCY).includes(outgoingCurrency.toLowerCase())) {
      throw new Error(`unknown balance currency ${outgoingCurrency}`);
    }

    if (isNil(targetRate)) {
      throw new Error('target rate is required');
    }

    if (isNil(targetAmount)) {
      throw new Error('target amount is required');
    }

    if (!targetCurrency) {
      throw new Error('target currency is required');
    }
    if (!Object.values(BANK_CURRENCY).includes(targetCurrency.toLowerCase())) {
      throw new Error(`unknown target currency ${targetCurrency}`);
    }

    const action = new TransactionAction({
      outgoingCurrency: outgoingCurrency.toLowerCase(),
      outgoingAmount: new Money(outgoingAmount, outgoingCurrency).toString(),
      totalFee: new Money(totalFee, outgoingCurrency).toString(),
      targetCurrency: targetCurrency.toLowerCase(),
      targetRate: parseFloat(targetRate),
      targetAmount: new Money(targetAmount, targetCurrency).toString(),
    });

    const serializedTransactionInvoiceAmounts = parseSerializedTransactionInvoiceAmounts(
      invoiceAmounts,
    );

    const { total, currency } = serializedTransactionInvoiceAmounts;
    const invoicedMoney = new Money(total, currency);
    const invoiceRateMap = new InvoiceRateMap(
      serializedTransactionInvoiceAmounts,
      { testAgainstMoney: invoicedMoney },
    );

    const {
      currency: invoiceCurrency,
      targetCurrency: invoiceTargetCurrency,
      orgCurrency,
      systemCurrency,
    } = invoiceRateMap.serializeCurrencies();

    const rateMap = TransactionRateMap.parseSerializedRates({
      balanceCurrency: action.getOutgoingCurrency(),
      targetCurrency: action.getTargetCurrency(),
      invoiceCurrency,
      invoiceTargetCurrency,
      orgCurrency,
      systemCurrency,
      ...rates,
      balanceToTargetRate: action.getTargetRate(),
    });

    const transactionRateMap = invoiceRateMap.toTransactionRateMap({
      balanceCurrency: action.getOutgoingCurrency(),
      targetCurrency: action.getTargetCurrency(),
      rateMapAtTransactionTime: rateMap,
    });

    this.details = {
      transactionMode,
      action,
      transactionRateMap,
      serializedTransactionInvoiceAmounts,
    };

    auditTransactionAmounts(this, {
      serializedTransactionInvoiceAmounts,
      rateMapAtInvoiceTime: invoiceRateMap,
      invoicedMoney,
      transactionMode,
    });
  }

  isZero() {
    const outgoingAmount = this.details.action.getOutgoingAmount();
    return new Money(outgoingAmount, this.details.action.getOutgoingCurrency()).eq(0);
  }

  /**
   * Serialize instance.
   * @return {object} serialized instance.
   */
  serialize() {
    const {
      transactionMode,
      action,
      serializedTransactionInvoiceAmounts,
    } = this.details;
    const res = {
      transactionMode,
      ...action.serialize(),
      rates: this.getRates(),
      invoiceAmounts: serializedTransactionInvoiceAmounts,
    };

    return res;
  }

  setFee(fee, feeCurrency) {
    const rates = this.getTransactionRateMap();
    let totalFee = fee;
    const outgoingCurrency = this.getOutgoingCurrency();
    if (feeCurrency.toUpperCase() !== outgoingCurrency.toUpperCase()) {
      totalFee = rates.convert({
        money: new Money(fee, feeCurrency),
        toCurrency: this.getOutgoingCurrency(),
      }).toString();
    }
    const netOutgoingAmount = this.getNetOutgoingAmount();
    this.init({
      ...this.serialize(),
      outgoingAmount: new Money(netOutgoingAmount, outgoingCurrency).add(totalFee).toString(),
      totalFee,
    });
  }

  inAnotherCurrency(currency, exchangeRate) {
    const { action } = this.details;
    assertAllKeysPresent({ currency, exchangeRate });
    return action.inAnotherCurrency(currency, exchangeRate);
  }

  get inBalanceCurrency() {
    const { action } = this.details;
    return this.inAnotherCurrency(action.getOutgoingCurrency(), 1);
  }

  get inTargetCurrency() {
    const { action } = this.details;
    const { balanceToTargetRate } = this.getRates();
    return this.inAnotherCurrency(action.getTargetCurrency(), balanceToTargetRate);
  }

  get inOrgCurrency() {
    const { balanceToOrgRate } = this.getRates();
    return this.inAnotherCurrency(this.getOrgCurrency(), balanceToOrgRate);
  }

  get inSystemCurrency() {
    const { balanceToSystemRate } = this.getRates();
    return this.inAnotherCurrency(this.getSystemCurrency(), balanceToSystemRate);
  }

  get inInvoiceCurrency() {
    const { balanceToInvoiceRate } = this.getRates();
    return this.inAnotherCurrency(this.getInvoiceCurrency(), balanceToInvoiceRate);
  }

  getCurrencies() {
    return this.getTransactionRateMap().serializeCurrencies();
  }

  getInvoiceCurrency() {
    const { invoiceCurrency: currency } = this.getCurrencies();
    return currency;
  }

  getInvoiceTargetCurrency() {
    const { invoiceTargetCurrency: currency } = this.getCurrencies();
    return currency;
  }

  getOrgCurrency() {
    const { orgCurrency: currency } = this.getCurrencies();
    return currency;
  }

  getSystemCurrency() { //eslint-disable-line
    return SYSTEM_CURRENCY;
  }

  getTransactionRateMap() {
    const { transactionRateMap } = this.details;
    return transactionRateMap;
  }

  toPayoneerPayoutFields() {
    const {
      action: { targetAmount: targetValue, targetCurrency },
    } = this.details;
    const { balanceToTargetRate: rate } = this.getRates();
    const { currency: sourceCurrency, outgoingAmount: sourceValue } = this.inBalanceCurrency;
    return {
      sourceValue,
      sourceCurrency,
      targetValue,
      targetCurrency,
      rate,
    };
  }

  toTransferwiseTransferFields() {
    const { action } = this.details;
    const { balanceToTargetRate: rate } = this.getRates();
    const targetValue = action.getTargetAmount();
    const targetCurrency = action.getTargetCurrency();
    const { currency: sourceCurrency, outgoingAmount: sourceValue } = this.inBalanceCurrency;
    return {
      sourceValue,
      sourceCurrency,
      targetValue,
      targetCurrency,
      rate,
    };
  }

  getTransactionMode() {
    const { transactionMode } = this.details;
    return transactionMode;
  }

  shouldProviderPayFees() {
    const transactionMode = this.getTransactionMode();

    const providerAbsorbsFees = transactionMode === TRANSACTION_MODE.FIXED_SO_AMOUNT;
    return providerAbsorbsFees;
  }

  getOutgoingAmount() {
    const { action } = this.details;
    return action.getOutgoingAmount();
  }

  getNetOutgoingAmount() {
    const { action } = this.details;
    return action.getNetOutgoingAmount();
  }

  getTotalFee() {
    const { action } = this.details;
    return action.getTotalFee();
  }

  getOutgoingCurrency() {
    const { action } = this.details;
    return action.getOutgoingCurrency();
  }

  getTargetAmount() {
    const { action } = this.details;
    return action.getTargetAmount();
  }

  getTargetRate() {
    const { action } = this.details;
    return action.getTargetRate();
  }

  getExpectedTargetRate() {
    const { action } = this.details;
    return action.getExpectedTargetRate();
  }

  getTargetCurrency() {
    const { action } = this.details;
    return action.getTargetCurrency();
  }

  getBalanceCurrency() {
    const { action } = this.details;
    return action.getOutgoingCurrency();
  }

  getNetOutgoingAmountInOrgCurrency() {
    const { netOutgoingAmount } = this.inOrgCurrency;
    return netOutgoingAmount;
  }

  getTargetExchangeRateCode() {
    const { exchangeRateCode } = this.inTargetCurrency;
    return exchangeRateCode;
  }

  hasDifferentBalance() {
    return this.getOrgCurrency() !== this.getOutgoingCurrency();
  }

  getAction() {
    const { action } = this.details;
    return action;
  }

  getRates() {
    return this.getTransactionRateMap().serializeRates();
  }

  convertToTargetCurrency(amount, currency) {
    const rateMap = this.getTransactionRateMap();
    const money = new Money(amount, currency);
    return rateMap.convert({ money, toCurrency: this.getTargetCurrency() }).toString();
  }

  getOutgoingMoney() {
    return new Money(this.getOutgoingAmount(), this.getOutgoingCurrency());
  }
}

export default TransactionAmounts;
